// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyC0cN0f9vJlLESMzs9KVXvswfzf8WQEmqw",
    authDomain: "investment-59429.firebaseapp.com",
    projectId: "investment-59429",
    storageBucket: "investment-59429.appspot.com",
    messagingSenderId: "647855965190",
    appId: "1:647855965190:web:96eb1874e55f6b73fc2f87"
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);


