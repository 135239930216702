// src/components/Signup.js
import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { Link, useNavigate } from 'react-router-dom';
import './Auth.css';
import { setDoc , doc } from 'firebase/firestore';
import { db } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'


const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      const user= auth.currentUser;
      if(user){
        await setDoc(doc(db,"Users", user.uid),{
          email: user.email,
          userName: username,
        })
      }
      toast.success("User Registered Successfully!", {position: "top-center"},)
      
      navigate('/login')
    } catch (error) {
      console.log(error.message);
      toast.error(error.message, {position: "top-center"},);
    }
  };

  return (
    <form onSubmit={handleSignup} className="auth-form">
      <h2>Investor Sign Up</h2>
      <ToastContainer/>
      <label>Username</label>
      <input
        type="text"
        placeholder="e.g JohnDoe"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
      />
      <label>Email</label>
      <input
        type="email"
        placeholder="e.g johndoe@gmail.com"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <label>Password</label>
      <input
        type="password"
        placeholder="Create a Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
      />
      <button type="submit">Sign Up</button>
      <p>Already have an account?  <Link to='/login'>Login</Link></p>
      
    </form>
    
  );
  
};

export default Signup;
