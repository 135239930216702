// src/components/JobList.js
import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import './Dashboard.css'
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const [investments, setInvestments] = useState([]);
  const [user, setUser] = useState(null);
  



  useEffect(() => {
    const fetchInvestments = async () => {
      const user = auth.currentUser;
    
      if (user) {
        setUser(user);
        const q = query(collection(db, 'investment'), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const investmentList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setInvestments(investmentList);
        
      }
     
    }
      
    
    fetchInvestments();
  }, []);
 
  


  return (
    <div className="dashboard">
      <div className='dashHeader'>
        <h3>Welcome, <span className='userEmail'>{user?.email}</span> </h3>
        <Link to='/invest' className='investLink'> Click here to  Invest </Link>
        
      </div>
      <div className='noticeboard'>
      <p>Balances below will start updating once you invest!</p>
      </div>
      <div className='status-display'>
            <span className='menu-container balanceBox'>Current Balance:<br/>{user?(<>
              {investments.map(investment => (
                <div key={investment.id} >
                 $ {investment.balance}
               </div>
             ))}
             </>
              
            ):(<><p>Loading...</p></>)} 
             </span>
            <span className='menu-container amountBox'>Initial Investment:
            {investments.map(investment => (
           <div key={investment.id} >
            $ {investment.amount}
            <Link to='/pay'><button className='paymentBtn'>PAY NOW</button></Link>
          </div>
        ))} </span>
            <span className='menu-container basisBox'>Total Profits Paid : <br/>
            {investments.map(investment => (
           <div key={investment.id} >
            $ {investment.profits}
          </div>
        ))}</span>
            <span className='menu-container methodBox'>Profit Sharing Basis: <br/> 
            {investments.map(investment => (
           <div key={investment.id} >
             {investment.withdrawal}
          </div>
        ))}</span> 
      </div>
      
    </div>
  );
};

export default Dashboard;
