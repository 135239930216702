import React from 'react'
import { Link } from 'react-router-dom'
import './Home.css'

function Home() {
  return (
    <div className='home-container'>
        <div className='overview'>
            <h1>360 TRADING HUB POOL TRADING PROGRAM.</h1>
            <h3>What's Pool Trading?</h3>
            <p>This is a program whereby  members who cant trade on their own due to one reason or the other contribute their capital into a common
                 Trading Account and Earn Profits as a percentage of their contribution amount. The Admin is responsible for taking trades, 
                 managing the Pool Account, receiving and Sharing Profits on Weekly and Monthly Basis.</p>

                 <li>
                    <ul>- The minimum Capital contribution is capped at $500.</ul>
                    <ul>- Our daily target profit is between 15-20% of Total Capital.</ul>
                    <ul>- We deduct $20 from your total profits as a service fee.</ul>
                    <ul>- We Use Fully Automated Premium Bots to ensure maximum profits as well as account safety.</ul>
                 </li>
                 <div className='nav-buttons'>
                    <div className='register-section'>
                        <h3>Want to Join?</h3>
                        <Link to='/register'><button className='registerBtn'>Register</button></Link>
                    </div>
                    <div className='register-section'>
                        <h3>Already a Member?</h3>
                        <Link to='/login'><button className='loginBtn'>Login</button></Link>
                    </div>
            
        </div>
        </div>
        
    </div>
  )
}

export default Home