import {Link , NavLink} from "react-router-dom"
import "./Navbar.css";
import React , {useState} from 'react'
import Logo from "../assets/logo.png"


function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
      <nav>
        <Link to="/" className="title">
            <img src={Logo} alt="logo"/>
        </Link>
        <div className="menu" onClick={() => setMenuOpen(!menuOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <ul className={menuOpen ? "open" : ""}>
          
          <li>
            <NavLink to="/pay">Submit Payment</NavLink>
          </li>
          <li>
            <NavLink to="/bots">Trading Bots</NavLink>
          </li>
          <li>
            <NavLink to="https://track.deriv.com/_UEAPSNb_-9WFfUyb_9NCN2Nd7ZgqdRLk/1/">Create Trading Account</NavLink>
          </li>
          <button className="login-btn"><NavLink to='/login'>Member Login</NavLink></button>
        </ul>
      </nav>
    );
}

export default Navbar