import React from 'react'
import './Payment.css'
import { ToastContainer, toast } from 'react-toastify'
import { Link } from 'react-router-dom'

function Payment() {
    const handleClick=()=> {
        toast.success("Thankyou! Your Portal Balance will be Updated once we confirm your Payment.", {position:"top-center"})
    }
  return (
    <div className='payment-container'>
        <div className='crypto-section'>
            <h2>Pay your investment Amount with USDT</h2>
            <h3> Copy & Paste the wallet address below</h3>
            <p>TPP7FcJphMHZDS4DBziH6EF7sfGR4Zp6im</p>
            <span>NETWORK : TRON (TRC-20)</span>
            <button onClick={handleClick}>I HAVE MADE MY PAYMENT</button>
            <Link to='/login'><button className='portalBtn'>Check Portal</button></Link>
            <ToastContainer/>
        </div>
    </div>
  )
}

export default Payment