// src/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import './App.css';
import Submit from './components/Submit';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Payment from './components/Payment';

function App() {
  return (
    
      <div className="App">
        <Navbar/>
        <Routes>
          <Route path="/register" element={<Register/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="/invest" element={<Submit/>} />
          <Route exact path="/" element={<Home/>} />
          <Route path="/pay" element={<Payment/>} />
        </Routes>
      </div>
    
  );
}

export default App;
